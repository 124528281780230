import React from 'react'
import { Link } from 'gatsby'

export default () => (
  <div className="copyright">
    <span>© 2020 REGYM</span>
    <Link to="/privacy" className="link">Privacy</Link>
    {/*<Link to="/terms" className="link">Terms</Link>*/}
  </div>
)

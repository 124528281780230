import React from 'react'
import { Helmet } from 'react-helmet'

import '../styles/privacy.css'

import Privacy from '../components/Privacy'
import Copyright from '../components/Copyright'
import Header from '../components/Header'

export default () => {
  return (
    <div id="privacy">
      <Helmet>
        <title>REGYM - Privacy and Policy</title>
      </Helmet>
      <Header />
      <Privacy
        footer={(
          <footer>
            <Copyright />
          </footer>
        )}
      />
    </div>
  )
}
